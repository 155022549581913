import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Layout from "../../components/layout/layout";
import {Col, Row} from "react-bootstrap";
import * as styles from "../../styles/policyPages.module.scss"
import {Helmet} from "react-helmet";

export default function PreSalesAndSolutionEngineeringPage() {

    const {markdownRemark} = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Pre-sales and Solutions Engineering" } }) {
        html
        frontmatter {
          title
        }
      }
    }
    `);

    return <>
        <Helmet>
            <title>{markdownRemark.frontmatter.title} - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header/>}
            footer={<Footer/>}
        >
            <Row>
                <Col className={styles.contentWrapper} lg={{offset: 2, span: 8}}>
                    <div className={styles.title}>
                        {markdownRemark.frontmatter.title}
                    </div>
                    <div className={styles.lastUpdated}>
                        &nbsp;
                    </div>
                    <div className={styles.content} dangerouslySetInnerHTML={{__html: markdownRemark.html}}/>
                </Col>
            </Row>
        </Layout>
    </>
}
